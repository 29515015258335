import store from '../store/index';
import router from '../router/index';
import Config from './Config';

const axios = require('axios').default;

export default class Auth {
  isloggued = false;

  authreturn = {
    status: 'OK',
    msg: '',
    bearer: '',
  };

  isGeoSet = false;

  logout() {
    this.isloggued = false;
    store.state.userisloggued = false;
    store.state.bearer = '';
    window.localStorage.removeItem('bearer');
    return true;
  }

  static validateIfCanAccess(accessLevel: string, redirect: string) {
    if (store.state.accessmode.indexOf(accessLevel) < 0) {
      router.push({ name: redirect });
    }
  }

  async isLoggued() {
    if (store.state.userisloggued) {
      this.isloggued = true;
      return this.isloggued;
    }

    const thebearer = window.localStorage.getItem('bearer');

    if (thebearer) {
      const self = this;
      const domain = Config.basedomain;
      return axios.post(`${domain}auth/checkbearer`, { bearer: thebearer }).then((response: any) => {
        if (response.data.status === 'OK') {
          store.state.userisloggued = true;
          self.isloggued = true;
          store.state.bearer = thebearer;
          store.state.firstname = response.data.firstname;
          store.state.lastname = response.data.lastname;
          store.state.accessmode = response.data.accessmode;
          return self.isloggued;
        }
        self.isloggued = false;
        store.state.userisloggued = false;
        store.state.bearer = '';
        return self.isloggued;
      }).catch(() => {
        self.isloggued = false;
        store.state.userisloggued = false;
        return self.isloggued;
      });
    }

    return this.isloggued;
  }

  async login(username: string, _password: string) {
    const self = this;
    const domain = Config.basedomain;

    return axios.post(`${domain}auth/login`, { email: username, password: _password }).then((response: any) => {
      self.authreturn.bearer = response.data.token;
      window.localStorage.setItem('bearer', self.authreturn.bearer);
      store.state.userisloggued = true;
      store.state.bearer = self.authreturn.bearer;
      store.state.firstname = response.data.firstname;
      store.state.lastname = response.data.lastname;
      store.state.accessmode = response.data.accessmode;
      return self.authreturn;
    }).catch((response: any) => {
      self.authreturn.status = 'ERROR';
      self.authreturn.msg = response.response.data.msg;
      return self.authreturn;
    });
  }

  async setGeoInfos() {
    const self = this;
    return axios.get(process.env.VUE_APP_API_URL + 'utils/get-geo').then((response: any) => {
      store.state.geoinfos = response.data.data;
      self.isGeoSet = true; // petit hack ici parce que si je n'utilise pas le self, le compilateur n'aime pas ça
      return true;
    });
  }
}
