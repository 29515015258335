<template>
  <div>
    <mdb-footer color="mdb-color" class="font-small lighten-3 pt-4 mt-4">
      <mdb-container v-if="mode==='PUBLIC'">
        <mdb-row>
          <mdb-col>
            <h5>Plus d'informations</h5>
          </mdb-col>
        </mdb-row>
        <mdb-row class="footerlink">
          <mdb-col>
            <p><a v-on:click="gotoallfuturwebinaires">Webinaires à venir</a></p>
          </mdb-col>
          <mdb-col>
            <p><a v-on:click="gotoallpastswebinaires">Webinaires passés</a></p>
          </mdb-col>
          <mdb-col>
            <p><a v-on:click="gotoapropos">À propos</a></p>
          </mdb-col>
          <mdb-col>
            <p><a href="/adm">Connexion</a></p>
          </mdb-col>
        </mdb-row>
      </mdb-container>

      <mdb-container class="text-center text-md-left" v-if="mode==='AGENT'">
        <mdb-row class="my-4">
          <mdb-col md="4" lg="4" class="text-center">
            <h5 class="text-uppercase mb-2 font-weight-bold">Votre Conseiller</h5>
            <mdb-avatar tag="img" :src="agentinfos.profilepic" circle class="rounded z-depth-1-half img-fluid" alt="Sample avatar"/>
            <h5 class="font-weight-bold mt-2 mb-1">{{agentinfos.firstname}} {{agentinfos.lastname}}</h5>
            <h5 class="white-text">{{agentinfos.agencyname}}</h5>
            <p class="text-uppercase black-text" style="margin-bottom:0!important;">{{agentinfos.title}}</p>
            <p v-if="agentinfos.ccv">Permis OPC #{{agentinfos.ccv}}</p>
            <ul class="list-unstyled mb-0">
              <a class="p-2 fa-lg" v-if="agentinfos.minisite" :href="agentinfos.minisite" target="_blank">
                <mdb-icon icon="home" class="white-text"/>
              </a>
              <a class="p-2 fa-lg" v-if="agentinfos.fbpage" :href="agentinfos.fbpage" target="_blank">
                <mdb-icon fab icon="facebook" class="white-text"/>
              </a>
            </ul>
          </mdb-col>
          <hr class="clearfix w-100 d-md-none"/>

          <mdb-col md="4" lg="4" class="text-center">
            <h5 class="text-uppercase mb-4 font-weight-bold">Coordonnées</h5>
            <p><mdb-icon icon="home" class="mr-3" /> {{agentinfos.address}}, {{agentinfos.city}} {{agentinfos.province}}</p>
            <p v-if="agentinfos.showemail && agentinfos.email"><mdb-icon icon="envelope" class="mr-3" /> <a :href="'mailto:'+agentinfos.email">{{agentinfos.email}}</a></p>
            <p v-if="agentinfos.showphone && agentinfos.phone"><mdb-icon icon="phone" class="mr-3" /> <a :href="'tel:'+agentinfos.phone">{{agentinfos.phone}}</a></p>
          </mdb-col>
          <hr class="clearfix w-100 d-md-none"/>
          <mdb-col md="4" lg="4" class="text-center">
            <h5 class="text-uppercase mb-4 font-weight-bold">À propos</h5>
            <ul class="list-unstyled">
              <p><a v-on:click="gotoallfuturwebinaires">Webinaires à venir</a></p>
              <p><a v-on:click="gotoallpastswebinaires">Webinaires passés</a></p>
              <p><a v-on:click="gotoapropos">À propos</a></p>
              <p><a href="/adm">Connexion</a></p>
            </ul>
          </mdb-col>
        </mdb-row>
      </mdb-container>

      <mdb-container class="text-center text-md-left" v-if="mode==='AGENCY'">
        <mdb-row class="my-4">
          <mdb-col md="4" lg="4" class="text-center">
            <h5 class="text-uppercase mb-2 font-weight-bold">Votre Agence de Voyages</h5>
            <mdb-card-avatar color="white" class="mx-auto">
              <img :src="agencyinfos.logo" class="" style="width:50%;">
            </mdb-card-avatar>
            <p v-if="agencyinfos.licensenumber">Permis #{{agencyinfos.licensenumber}}</p>
            <ul class="list-unstyled mb-0">
              <a class="p-2 fa-lg" v-if="agencyinfos.website" :href="agencyinfos.website" target="_blank">
                <mdb-icon icon="home" class="white-text"/>
              </a>
              <a class="p-2 fa-lg" v-if="agencyinfos.fbpage" :href="agencyinfos.fbpage" target="_blank">
                <mdb-icon fab icon="facebook" class="white-text"/>
              </a>
            </ul>
          </mdb-col>
          <hr class="clearfix w-100 d-md-none"/>

          <mdb-col md="4" lg="4" class="text-center">
            <h5 class="text-uppercase mb-4 font-weight-bold">Coordonnées</h5>
            <p><mdb-icon icon="home" class="mr-3" /> {{agencyinfos.address}}, {{agencyinfos.city}} {{agencyinfos.province}}</p>
            <p v-if="agencyinfos.email"><mdb-icon icon="envelope" class="mr-3" /> <a :href="'mailto:'+agencyinfos.email"></a></p>
            <p v-if="agencyinfos.phone"><mdb-icon icon="phone" class="mr-3" /> <a :href="'tel:'+agencyinfos.phone">{{agencyinfos.phone}}</a></p>
            <p v-if="agencyinfos.tollfree"><mdb-icon icon="phone" class="mr-3" /> <a :href="'tel:'+agencyinfos.tollfree">{{agencyinfos.tollfree}}</a></p>
          </mdb-col>
          <hr class="clearfix w-100 d-md-none"/>
          <mdb-col md="4" lg="4" class="text-center">
            <h5 class="text-uppercase mb-4 font-weight-bold">À propos</h5>
            <ul class="list-unstyled">
              <p><a v-on:click="gotoallfuturwebinaires">Webinaires à venir</a></p>
              <p><a v-on:click="gotoallpastswebinaires">Webinaires passés</a></p>
              <p><a v-on:click="gotoapropos">À propos</a></p>
              <p><a href="/adm">Connexion</a></p>
            </ul>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      <div class="footer-copyright text-center py-3">
        <mdb-container fluid>
          &copy; 2021 Copyright: <a href="https://voyagesendirect.com"> Voyages en direct </a>
        </mdb-container>
      </div>
    </mdb-footer>
  </div>
</template>

<script>
    import {
        mdbFooter,
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbAvatar,
        mdbCardAvatar,
    } from 'mdbvue';

    import Apicall from '../libs/Apicall';

    export default {
        name: 'Adminfooter',
        data() {
            return {
                mode: 'PUBLIC',
                agentinfos: {
                    firstname: '',
                    lastname: '',
                    agencyname: '',
                    email: '',
                    profilepic: '',
                    minisite: '',
                    title: '',
                    fbpage: '',
                    cell: '',
                    phone: '',
                    showphone: true,
                    showemail: true,
                    ccv: '',
                    address: '',
                    city: '',
                    province: '',
                    country: '',
                    postalcode: '',
                },
                agencyinfos: {
                    address: '',
                    agencyname: '',
                    city: '',
                    country: '',
                    email: '',
                    fbpage: '',
                    licensenumber: '',
                    logo: '',
                    phone: '',
                    postalcode: '',
                    province: '',
                    tollfree: '',
                    website: '',
                },
            };
        },
        components: {
            mdbFooter,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbIcon,
            mdbAvatar,
            mdbCardAvatar,
        },
        mounted() {
            const self = this;
            this.$store.watch(() => this.$store.state.agentcontext, () => { self.reloadWithChangedContext('AGENT'); });
            this.$store.watch(() => this.$store.state.agencycontext, () => { self.reloadWithChangedContext('AGENCY'); });
            this.loadWithContext();
        },
        methods: {
            loadWithContext() {
                this.agentinfos = {};
                this.agencyinfos = {};
                if (this.$store.state.agentcontext) {
                    this.mode = 'AGENT';
                    if (!this.$store.state.agentinfosleaded) {
                        const self = this;
                        const api = new Apicall();
                        api.getAgentInfos(this.$store.state.agentcontext).then((response) => {
                            if (response.status === 'OK') {
                                self.$store.state.agentinfos = response.data.infos;
                                self.$store.state.agentinfosleaded = true;
                                self.agentinfos = self.$store.state.agentinfos;
                            } else {
                                this.$store.state.agentcontext = null;
                                window.localStorage.removeItem('agentcontext');
                                self.$router.push({ name: 'Home' });
                            }
                        });
                    } else {
                        this.agentinfos = this.$store.state.agentinfos;
                    }
                }
                if (this.$store.state.agencycontext) {
                    this.mode = 'AGENCY';
                    if (!this.$store.state.agencyinfosleaded) {
                        const self = this;
                        const api = new Apicall();
                        api.getAgencyInfos(this.$store.state.agencycontext).then((response) => {
                            if (response.status === 'OK') {
                                self.$store.state.agencyinfos = response.data.infos;
                                self.$store.state.agencyinfosleaded = true;
                                self.agencyinfos = self.$store.state.agencyinfos;
                            } else {
                                this.$store.state.agencycontext = null;
                                window.localStorage.removeItem('agencycontext');
                                self.$router.push({ name: 'Home' });
                            }
                        });
                    } else {
                        this.agencyinfos = this.$store.state.agencyinfos;
                    }
                }
            },
            reloadWithChangedContext(type) {
                this.$store.state.agentinfosleaded = false;
                this.$store.state.agencyinfosleaded = false;
                if (type === 'AGENT' && this.$store.state.agentcontext) {
                    console.log('reload agent');
                    this.mode = 'AGENT';
                    const self = this;
                    const api = new Apicall();
                    api.getAgentInfos(this.$store.state.agentcontext).then((response) => {
                        if (response.status === 'OK') {
                            self.$store.state.agentinfos = response.data.infos;
                            self.$store.state.agentinfosleaded = true;
                            self.agentinfos = self.$store.state.agentinfos;
                        } else {
                            this.$store.state.agentcontext = null;
                            window.localStorage.removeItem('agentcontext');
                            self.$router.push({ name: 'Home' });
                        }
                    });
                }

                if (type === 'AGENCY' && this.$store.state.agencycontext) {
                    console.log('reload agency');
                    this.mode = 'AGENCY';
                    const self = this;
                    const api = new Apicall();
                    api.getAgencyInfos(this.$store.state.agencycontext).then((response) => {
                        if (response.status === 'OK') {
                            self.$store.state.agencyinfos = response.data.infos;
                            self.$store.state.agencyinfosleaded = true;
                            self.agencyinfos = self.$store.state.agencyinfos;
                        } else {
                            this.$store.state.agencycontext = null;
                            window.localStorage.removeItem('agencycontext');
                            self.$router.push({ name: 'Home' });
                        }
                    });
                }
            },
            gotoapropos() {
                this.$router.push({ name: 'Apropos' });
            },
            gotoallpastswebinaires() {
                this.$router.push({ name: 'Ancienswebinaires' });
            },
            gotoallfuturwebinaires() {
                this.$router.push({ name: 'Futurwebinaires' });
            },
        },
    };
</script>

<style scoped>
  .footerlink a:hover{
    text-decoration: underline;
    font-weight: bold;
  }
</style>
