<template>
  <div>
    <mdb-card wide>
      <mdb-view hover cascade>
        <a v-on:click="gotoRegisterPage">
          <mdb-card-image :src="webinar.coverimage" alt="Card image cap" ></mdb-card-image>
          <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
        </a>
      </mdb-view>
      <mdb-card-body class="text-center" cascade>
        <mdb-card-title><strong>{{webinar.title}}</strong></mdb-card-title>
        <h5 class="orange-text"><strong>{{webinar.eventdate}}</strong></h5>
        <h6 class="blue-text" v-if="webinar.presentedby">Présenté par: <strong>{{webinar.presentedby}}</strong></h6>
        <mdb-card-text>{{webinar.minidesc}} </mdb-card-text>
        <mdb-btn color="elegant" v-on:click="gotoRegisterPage" v-if="past">Informations</mdb-btn>
        <mdb-btn color="elegant" v-on:click="gotoRegisterPage" v-else>Inscription <mdb-icon icon="sign-in-alt" class="ml-1"/></mdb-btn>
      </mdb-card-body>
    </mdb-card>
  </div>
</template>

<script>
    import {
        mdbCard,
        mdbView,
        mdbCardImage,
        mdbMask,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbBtn,
        mdbIcon,
    } from 'mdbvue';

    export default {
        name: 'Webinartile',
        props: {
            webinar: Object,
            past: String,
        },
        data() {
            return {
            };
        },
        components: {
            mdbCard,
            mdbView,
            mdbCardImage,
            mdbMask,
            mdbCardBody,
            mdbCardTitle,
            mdbCardText,
            mdbBtn,
            mdbIcon,
        },
        methods: {
            gotoRegisterPage() {
                this.$router.push({ name: 'Registerwebinaire', params: { ident: this.webinar.permalink } });
            },
        },
    };
</script>
