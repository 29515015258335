import config from './Config';
import store from '../store/index';

const axios = require('axios').default;

export default class Apicall {
  return = {
    status: 'OK',
    data: null,
    msg: null,
  };

  header = {};

  getHeaders() {
    const thebearer = store.state.bearer;
    this.header = {
      headers: {
        Authorization: `Bearer ${thebearer}`,
      },
    };
    return this.header;
  }

  async addWebinaire(infos: Array<any>) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/add`, infos, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async addAdministrateur(infos: Array<any>) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admadmin/add`, infos, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getAllAdmins() {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.get(`${domain}admadmin/list`, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getOneAdmin(_adminid: string) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admadmin/get-one`, { adminid: _adminid }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async editAdministrateur(infos: Array<any>) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admadmin/edit`, infos, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async deleteAdmin(_adminid: string) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admadmin/delete`, { adminid: _adminid }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getFuturesWebinarAdmin() {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/list`, { type: 'FUTUR_AND_ON_AIR' }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getAllWebinairesAdmin() {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/list`, { type: 'ALL' }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async deleteWebinaire(_ident: string) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/delete`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getOneWebinarAdmin(_ident: string) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/get-one`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async editWebinaire(infos: Array<any>) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/edit`, infos, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getWebinaireBaseStats(_ident: string) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/get-base-stats-for-one`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getWebinaireQuestions(_ident: string) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/get-questions-for-one`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getViewersGraphInfos(_ident: string) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/get-viewers-graph-infos`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async setQuestionNewState(_theid: string, _newstate: string) {
    console.log(_theid);
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/set-question-new-state`, { theid: _theid, newstate: _newstate }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getReviewsGraphInfos(_ident: string) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/get-reviews-graph-infos`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }
  // Fin call dashboard

  async loadWebinars(_query: Array<any>) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}webinar/list`, _query, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async loadWebinar(_ident: string, _context: Array<any>) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}webinar/get-one`, { ident: _ident, context: _context }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async registertowebinar(_infos: Array<any>, _permalink: string, _geo: Array<any>) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}webinar/register`, { permalink: _permalink, infos: _infos, geo: _geo }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getAgentInfos(_ident: string) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}agentagency/get-agent`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getAgencyInfos(_ident: string) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}agentagency/get-agency`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async validateYoutubeId(_youtubeid: string) {
    const self = this;
    const domain = config.basedomainAdmin;
    return axios.post(`${domain}admwebinar/validate-youtube-id`, { youtubeid: _youtubeid }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async askquestion(_infos: Array<any>, _geo: Array<any>) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}misc/inquiry`, { infos: _infos, geo: _geo }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getPlayerInfos(_ident: string) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}webinar/get-player-data`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async pingView(_ident: string) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}webinar/ping-view`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getVideoState(_ident: string) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}webinar/get-video-state`, { ident: _ident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async askQuestion(_question: string, _playerident: string) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}webinar/ask-question`, { playerident: _playerident, question: _question }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async getWebinaireReviewData(_playerident: string) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}webinar/get-infos-for-review`, { playerident: _playerident }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }

  async saveReview(_playerident: string, _note: string, _comment: string) {
    const self = this;
    const domain = config.basedomain;
    return axios.post(`${domain}webinar/save-review`, { playerident: _playerident, note: _note, comment: _comment }, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.data.msg;
      return self.return;
    });
  }
}
