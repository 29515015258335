<template>
  <mdb-container>
    <Publicheader activepage="Home"></Publicheader>
    <main>
        <mdb-row>
          <mdb-col md="12" class="mb-4 mt-4">
            <mdb-card class="card-image" style="background-image: url(/images/img_frontpage.jpg)">
              <div class="text-white text-center py-5 px-4 rgba-stylish-strong">
                <div>
                  <h2 class="card-title h1-responsive pt-3 mb-5 font-bold"><strong>Connexion Voyages</strong></h2>
                  <p class="mx-5">Des conférences inspirantes vous connectant avec les plus beaux coins du monde.</p>
                  <p class="mx-5 mb-5">Inscrivez-vous gratuitement dès maintenant!</p>
                  <mdb-btn outline="white" size="md" icon="clone" v-on:click="gotoallfuturwebinaires">Nos conférences à venir</mdb-btn>
                </div>
              </div>
            </mdb-card>
          </mdb-col>
        </mdb-row>
    </main>
    <section>
      <h3>Prochains Webinaires</h3>
      <mdb-row v-if="errormsg">
        <mdb-col md="12">
          <mdb-alert color="danger">
            {{errormsg}}
          </mdb-alert>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col md="4" class="pb-3" v-for="webinar in webinars" :key="webinar.id">
          <Webinartile v-bind:webinar="webinar"></Webinartile>
        </mdb-col>
        <mdb-col v-if="webinars.length === 0">
          <mdb-alert color="info" class="text-center">
            <h2>Aucun webinaire à venir!</h2>
          </mdb-alert>
        </mdb-col>
      </mdb-row>
      <mdb-row class="justify-content-end" v-if="hasMoreThan3Webinars">
        <mdb-col md="4" class="text-right">
          <mdb-btn outline="elegant" v-on:click="gotoallfuturwebinaires">Tous les webinaires à venir <mdb-icon icon="angle-double-right" /></mdb-btn>
        </mdb-col>
      </mdb-row>
    </section>

    <Publicfooter></Publicfooter>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Publicheader from '@/components/Publicheader.vue';
    import Publicfooter from '@/components/Publicfooter.vue';
    import Webinartile from '@/components/Webinartile.vue';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbBtn,
        mdbIcon,
        mdbAlert,
    } from 'mdbvue';

    import Apicall from '../libs/Apicall';

    export default Vue.extend({
        name: 'Home',
        components: {
            Publicheader,
            Publicfooter,
            Webinartile,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbCard,
            mdbBtn,
            mdbIcon,
            mdbAlert,
        },
        data() {
            return {
                hasMoreThan3Webinars: false,
                webinars: [],
                errormsg: '',
            };
        },
        mounted() {
            const self = this;
            this.$store.watch(() => this.$store.state.agentcontext, () => { self.reloadWithChangedContext(); });
            this.$store.watch(() => this.$store.state.agencycontext, () => { self.reloadWithChangedContext(); });
            this.loadAndDisplayNext3Webinars();
        },
        methods: {
            reloadWithChangedContext() {
                // peut-être d'autre chose à faire pour le reload
                this.loadAndDisplayNext3Webinars();
            },

            loadAndDisplayNext3Webinars() {
                const self = this;
                const api = new Apicall();
                const query = {
                    agencycontext: this.$store.state.agencycontext,
                    agentcontext: this.$store.state.agentcontext,
                    filter: 'FUTURONLY',
                    page: 1,
                    nbPerPage: 3,
                };
                api.loadWebinars(query).then((response) => {
                    if (response.status === 'OK') {
                        self.webinars = response.data.webinars;
                        self.hasMoreThan3Webinars = response.data.nbTotalResults > 3;
                    } else {
                        self.errormsg = response.msg;
                    }
                });
            },

            gotoallfuturwebinaires() {
                this.$router.push({ name: 'Futurwebinaires' });
            },
        },
    });
</script>
