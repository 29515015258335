import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/adm',
    redirect: '/adm/dashboard',
  },
  {
    path: '/adm/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "TheDashboard" */ '../views/Dashboard.vue'),
  },
  {
    path: '/adm/webinaire/add',
    name: 'Addwebinaire',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "addwebinaire" */ '../views/AddWebinaire.vue'),
  },
  {
    path: '/adm/webinaire/edit/:ident',
    name: 'Viewwebinaire',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "viewwebinaire" */ '../views/ViewWebinaire.vue'),
  },
  {
    path: '/adm/webinaire/stats/:ident',
    name: 'Statswebinaire',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "statswebinaire" */ '../views/Statswebinaire.vue'),
  },
  {
    path: '/adm/webinaire/list',
    name: 'Webinairelist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "listwebinaire" */ '../views/ListWebinaire.vue'),
  },
  {
    path: '/adm/admin/list',
    name: 'Adminlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Adminlist" */ '../views/Adminlist.vue'),
  },
  {
    path: '/adm/admin/add',
    name: 'Adminadd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Adminadd" */ '../views/Adminadd.vue'),
  },
  {
    path: '/adm/admin/edit/:adminid',
    name: 'Adminedit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AdminEditt" */ '../views/Adminedit.vue'),
  },
  {
    path: '/webinaire/:ident/:agency?/:agent?',
    name: 'Registerwebinaire',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "viewwebinaire" */ '../views/RegisterWebinaire.vue'),
  },
  {
    path: '/webinaires',
    name: 'Futurwebinaires',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lesfuturwebinaires" */ '../views/FuturWebinaires.vue'),
  },
  {
    path: '/anciens-webinaires',
    name: 'Ancienswebinaires',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lesancienswebinaires" */ '../views/AnciensWebinaires.vue'),
  },
  {
    path: '/player/:ident',
    name: 'Player',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "leplayer" */ '../views/Player.vue'),
  },
  {
    path: '/a-propos',
    name: 'Apropos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "apropos" */ '../views/Apropos.vue'),
  },
  {
    path: '/review/:ident/:note',
    name: 'Review',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "review" */ '../views/Review.vue'),
  },
  {
    path: '*',
    name: '404Page',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
