<template>
  <mdb-container>
    <mdb-navbar class="sticky-top" color="white" light>
      <mdb-navbar-brand to="/">
        Connexion Voyages
      </mdb-navbar-brand>
      <mdb-navbar-toggler>
        <mdb-navbar-nav>
          <mdb-nav-item v-bind:class="{ 'isactive': checkActive('Home') }" v-on:click="gotohome" color="black">Accueil</mdb-nav-item>
          <mdb-nav-item v-bind:class="{ 'isactive': checkActive('cominwebinaires') }" v-on:click="gotoallfuturwebinaires">Webinaires à venir</mdb-nav-item>
          <mdb-nav-item v-bind:class="{ 'isactive': checkActive('About') }" v-on:click="gotoapropos" >À propos</mdb-nav-item>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>
  </mdb-container>
</template>

<script>
    import {
        mdbNavbar,
        mdbNavbarNav,
        mdbNavbarToggler,
        mdbContainer,
        mdbNavItem,
        mdbNavbarBrand,
    } from 'mdbvue';

    export default {
        name: 'Adminheader',
        props: {
            activepage: String,
        },
        data() {
            return {
                username: '',
                showmyaccount: false,
            };
        },
        components: {
            mdbContainer,
            mdbNavbar,
            mdbNavbarNav,
            mdbNavbarToggler,
            mdbNavItem,
            mdbNavbarBrand,
        },
        methods: {
            checkActive(name) {
                return name === this.activepage;
            },
            gotoapropos() {
                this.$router.push({ name: 'Apropos' });
            },
            gotohome() {
                this.$router.push({ name: 'Home' });
            },
            gotoallfuturwebinaires() {
                this.$router.push({ name: 'Futurwebinaires' });
            },
        },
    };
</script>

<style scoped>
  .isactive{
    font-weight: 500;
    text-decoration: underline;
  }
</style>
