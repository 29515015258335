import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbvue/lib/css/mdb.min.css';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Auth from './libs/Auth';

// Désactive sentry en JS pour l'instant
if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV === 'development') {
  if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.1,
    });
  }
}

Vue.config.productionTip = false;

if (window.localStorage.getItem('agencycontext')) {
  store.state.agencycontext = window.localStorage.getItem('agencycontext') ?? '';
}

if (window.localStorage.getItem('agentcontext')) {
  store.state.agentcontext = window.localStorage.getItem('agentcontext') ?? '';
}

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'Home' && to.name !== 'Registerwebinaire' && to.name !== 'Futurwebinaires' && to.name !== 'Ancienswebinaires' && to.name !== 'Apropos' && to.name !== 'Player' && to.name !== 'Review') {
    const auth = new Auth();
    auth.isLoggued().then((response) => {
      if (response) {
        next();
      } else {
        next({ name: 'Login' });
      }
    });
  } else {
    next();
  }
});

const auth = new Auth();
auth.setGeoInfos();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
